import Vue from 'vue';
import Router from 'vue-router';
import Safewalk from '@/pages/Safewalk.vue';
import Ride from '@/pages/Ride.vue';
// import Customer from '@/pages/Customer.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/safewalk',
      name: 'Safewalk',
      component: Safewalk,
    },
    {
      path: '/ride',
      name: 'Ride',
      component: Ride,
    },
  ],
});
