<template>
  <div ref="mapRef" id="map"></div>
</template>

<script>
import L from 'leaflet';

export default {
  name: 'MapComponent',
  props: {
    mapOffset: {
      type: String,
      default: '100px',
    },
    currentLocation: {
      type: Object,
      required: true,
    },
    destination: {
      type: Object,
      required: true,
    },
  },
  watch: {
    currentLocation(newVal) {
      const newLatLng = new L.LatLng(newVal.lat, newVal.lng);
      this.markers.currentLocation.setLatLng(newLatLng);
      this.map.instance.setView(newLatLng, 19);
      // this.setCurrentLocation(newVal);
    },
  },
  data: () => ({
    map: {
      instance: null,
      url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
      subdomains: 'abcd',
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      options: {
        zoomControl: false,
      },
    },
    markers: {
      currentLocation: null,
      destination: null,
    },
    group: null,
  }),
  methods: {
    setCurrentLocation(currentLocation) {
      const carIcon = L.icon({
        // eslint-disable-next-line global-require,import/no-unresolved
        iconUrl: require('../assets/person.png'),
        iconAnchor: [28, 48],
      });
      this.markers.currentLocation = L.marker(currentLocation, {
        icon: carIcon,
      }).addTo(this.map.instance);
    },
  },
  mounted() {
    this.map.instance = L.map('map', {
      center: this.currentLocation,
      zoom: 12,
      zoomControl: false,
    });

    L.tileLayer(this.map.url, {
      attribution: this.map.attribution,
      maxZoom: 18,
    }).addTo(this.map.instance);

    const destinationIcon = L.icon({
      // eslint-disable-next-line global-require,import/no-unresolved
      iconUrl: require('../assets/dropoff.png'),
      iconAnchor: [24, 46],
    });
    this.setCurrentLocation(this.currentLocation);
    this.markers.destination = L.marker(this.destination, { icon: destinationIcon })
      .addTo(this.map.instance);
    this.$nextTick(() => {
      this.map.instance.setActiveArea({
        position: 'absolute',
        top: '156px',
        bottom: this.mapOffset,
        width: '100vw',
      }, true);
    });
  },
};
</script>

<style scoped>
#map {
  width: 100vw;
  height: 100vh;
  z-index: 0;
}
</style>
