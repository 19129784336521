/* eslint-disable */
import Vue from 'vue';
import { Icon } from 'leaflet';
import { LMap, LTileLayer, LMarker, LFeatureGroup } from 'vue2-leaflet';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import 'leaflet/dist/leaflet.css';
import 'leaflet-active-area/src/leaflet.activearea.js';
import 'leaflet-rotatedmarker/leaflet.rotatedMarker.js';

Vue.config.productionTip = false;

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-feature-group', LFeatureGroup);

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount('#app');
