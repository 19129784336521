<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      height="75"
    >
      <v-spacer />

      <v-img
        alt="SafeKab Logo"
        class="shrink mr-2"
        contain
        src="@/assets/logo.png"
        transition="scale-transition"
        width="160"
      />

      <v-spacer />
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
};
</script>
