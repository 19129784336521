<template>
  <v-container class="justify-center pa-0">
    <v-img width="500" src="../assets/error.svg"></v-img>
    <h2>Walk not active</h2>
  </v-container>
</template>

<script>
export default {
  name: 'ErrorCom',
};
</script>

<style scoped>

</style>
