<template>
  <v-container class="pa-0" fluid>
    <template v-if="status.page === 'LOADING'">
      <div class="d-flex">
        <div class="justify-center align-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </div>
    </template>
    <template v-else-if="status.page === 'FAILED'">
      <div class="d-flex">
        <div class="justify-center align-center fixed-center text-center" style="width: 100%;">
          <ErrorCom></ErrorCom>
        </div>
      </div>
    </template>
    <template v-else>
      <Map
        :currentLocation="currentLocation"
        :destination="destination"
        map-offset="150px"
      />
    </template>
  </v-container>
</template>

<script>
import Map from '@/components/Map.vue';
// import SOSCard from '@/components/SOSCard.vue';
import ErrorCom from '@/components/Error.vue';
import axios from '@/plugins/axios';

export default {
  name: 'SafewalkPage',
  components: {
    Map,
    // SOSCard,
    ErrorCom,
  },
  data: () => ({
    status: {
      page: 'LOADING',
    },
    car: {},
    destination: {},
    currentLocation: {},
    id: null,
  }),
  methods: {
    getJourneyDetails() {
      const that = this;
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      this.id = urlParams.get('id');
      if (this.id != null && this.id !== '') {
        axios.post('/journey_details', {
          command: 'journey_details',
          jobID: that.id,
          journeyType: 'SafeKab',
        }).then((response) => {
          console.log(response);
          const { data } = response;
          const { dropoff } = data;
          that.destination = dropoff;
          that.getCurrentLocation();
          // eslint-disable-next-line
          setInterval(function() {
            that.getCurrentLocation();
          }, 30000);
          that.getCurrentLocation();
        }).catch(() => {
          that.status.page = 'FAILED';
        });
      } else {
        that.status.page = 'FAILED';
      }
    },
    getCurrentLocation() {
      axios.post('/track_driver', {
        command: 'track_me',
        jobID: this.id,
      }).then((response) => {
        const { Cars } = response.data;
        const [car] = Cars;
        this.currentLocation = {
          lat: car.latitude,
          lng: car.longitude,
        };
        this.status.page = 'SUCCESS';
      }).catch(() => {
        this.status.page = 'FAILED';
      });
    },
  },
  mounted() {
    this.getJourneyDetails();
  },
};
</script>

<style scoped>

</style>
